import PropTypes from "prop-types"
import React, { useEffect, useCallback, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation, useNavigate } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  USER_TYPE,
} from "../../helpers/constants"
import { logoutUser } from "../../store/auth/login/actions"
import { registerUserSuccessful } from "../../store/auth/register/actions"

const SidebarContent = props => {
  const location = useLocation()
  const ref = useRef()
  const path = location.pathname
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))

  useEffect(() => {
    if (!user) {
      let authUser = localStorage.getItem("authUser")
      console.log("=== authUser", authUser)

      try {
        authUser = JSON.parse(authUser)
        dispatch(registerUserSuccessful(authUser.user))
        return
      } catch (error) {
        console.error(error)
        dispatch(logoutUser(navigate))
      }
    }
  }, [user])
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = location.pathname
    const fullPath = pathName
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li style={{ display: "hidden" }}>
              {/*<i className="ti-user"></i>*/}
              <span></span>
            </li>
            <li style={{ display: "hidden" }}>
              {/*<i className="ti-user"></i>*/}
              <span></span>
            </li>
            {user && user.userType === USER_TYPE.ADMIN && (
              <>
                <li>
                  <Link to="/admins" className="waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Admin Users")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/companies" className="waves-effect">
                    <i className="fas fa-building"></i>
                    <span>{props.t("Companies")}</span>
                  </Link>
                </li>
              </>
            )}
            {user &&
              (user.userType === USER_TYPE.ADMIN) && (
                <li>
                  <Link to="/users" className="waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
              )}

            {user &&
              (
                user.userType === USER_TYPE.AGENT ||
                user.userType === USER_TYPE.COMPANY) && (
                <li>
                  <Link to="/users" className="waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
              )}
              {/*{user &&*/}
              {/*(*/}
              {/*  user.userType === USER_TYPE.COMPANY) && (*/}
              {/*  <li>*/}
              {/*    <Link to="/company-users" className="waves-effect">*/}
              {/*      <i className="ti-user"></i>*/}
              {/*      <span>{props.t("Company Users")}</span>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
            {user &&
              (user.userType === USER_TYPE.ADMIN) && (
                <li>
                  <Link to="/games" className="waves-effect">
                    <i className="fas fa-gamepad"></i>
                    <span>{props.t("Games")}</span>
                  </Link>
                </li>
              )}
            {user &&
              (user.userType === USER_TYPE.ADMIN ||
                user.userType === USER_TYPE.COMPANY || user.userType === USER_TYPE.AGENT) && (
                <li>
                  <Link to="/transactions" className="waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Transactions")}</span>
                  </Link>
                </li>
              )}
            {user &&
              (
              user.userType === USER_TYPE.ADMIN || user.userType === USER_TYPE.COMPANY) && (
                <li>
                  <Link to="/shops" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Shops")}</span>
                  </Link>
                </li>
              )}
            {user &&
              (user.userType === USER_TYPE.AGENT) && (
                <li>
                  <Link to="/coupons" className="waves-effect">
                    <i className="fas fa-receipt"></i>
                    <span>{props.t("Coupons")}</span>
                  </Link>
                </li>
              )}

            <li>
              <Link to="/Logout" className="waves-effect">
                <i className="sub-menu"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
