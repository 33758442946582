import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { API_URL } from "../../helpers/api_helper"
import axios from "axios"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import Select from "react-select"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormFeedback,
  Input,
  Label,
  FormGroup,
} from "reactstrap"
import { logoutUser } from "../../store/auth/login/actions"
import { GET_COUPONS, GET_GAMES } from "../../helpers/url_helper"
import DeleteConfirmationModal from "common/data/confirmModal"

const columnForCoupons = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Coupon Image",
    field: "couponImage",
    sort: "asc",
    width: 150,
    attributes: {
      style: { width: "235px" },
    },
  },
  {
    label: "Description",
    field: "description",
    sort: "asc",
    width: 270,
  },
  {
    label: "Price",
    field: "price",
    sort: "asc",
    width: 200,
  },
  {
    label: "Available",
    field: "couponsAvailable",
    sort: "asc",
    width: 100,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 200,
  },
]

const Coupons = props => {
  const [coupons, setCoupons] = useState({
    columns: columnForCoupons,
    rows: [],
  })

  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)

  const [games, setGames] = useState([])
  const gameOptions = games.map(game => ({
    value: game.id,
    label: game.name,
  }))

  const [refetch, setRefetch] = useState(0)

  const handleEdit = (couponId, coupons) => {
    const coupon = coupons.find(coupon => coupon.id === couponId)
    if (coupon) {
      setIsEditMode(true)
      setSelectedCoupon(coupon)
      const existingFile = {
        preview: coupon.couponImage,
      };
      setselectedFiles([existingFile]);
      toggleModalVisibility()
    }
  }



  function handleDelete(userId) {
    let authUser = localStorage.getItem("authUser")

    try {
      authUser = JSON.parse(authUser)
    } catch (error) {
      console.error(error)
      dispatch(logoutUser(navigate))
      return
    }
    const token = authUser.token
    const url = `${API_URL}${GET_COUPONS}`
    axios({
      method: "DELETE",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: {
        id: userId,
      },
    })
      .then(response => {
        setRefetch(refetch + 1)
        toast.success("coupon deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
  }

  useEffect(() => {
    function fetchCoupns() {
      try {
        let authUser = localStorage.getItem("authUser")

        try {
          authUser = JSON.parse(authUser)
        } catch (error) {
          console.error(error)
          dispatch(logoutUser(navigate))
          return
        }
        const token = authUser.token
        const url = `${API_URL}${GET_COUPONS}`
        axios({
          method: "GET",
          url,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then(response => {
            const coupons = response.data.data;
            const rows = coupons.map(coupon => {
              return {
                name: coupon.name,
                description: coupon.description,
                price: coupon.price,
                couponsAvailable: coupon.couponsAvailable,
                couponImage: (
                  <img
                    src={coupon.couponImage}
                    alt="Coupon"
                    className="w-25 h-auto"
                  />
                ),
                actions: (
                  <div>
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => handleEdit(coupon.id, coupons)}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteClick(+coupon.id)}
                    >
                      Delete
                    </Button>
                  </div>
                ),
              }
            })
            setCoupons({
              columns: columnForCoupons,
              rows,
            })
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
    fetchCoupns()
  }, [refetch])

  useEffect(() => {
    function fetchGames() {
      try {
        let authUser = localStorage.getItem("authUser")

        try {
          authUser = JSON.parse(authUser)
        } catch (error) {
          console.error(error)
          dispatch(logoutUser(navigate))
          return
        }
        const token = authUser.token
        const url = `${API_URL}${GET_GAMES}`
        axios({
          method: "GET",
          url,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then(response => {
            const rows = response.data.data.map(game => {
              return {
                id: game.id,
                name: game.name,
                image: game.image,
              }
            })
            setGames(rows)
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
    fetchGames()
  }, [])
  document.title = "Coupons"
  const [modalVisibility, setModalVisibility] = useState(false)
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [couponToDelete, setCouponToDelete] = useState(null)

  const toggleModalVisibility = () => {
    setModalVisibility(!modalVisibility)
  }
  const [selectedFiles, setselectedFiles] = useState([])
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if (files.length >= 1) {
      setselectedFiles([files[0]])
    }
  }
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      couponName: (selectedCoupon && selectedCoupon.name) || "",
      couponDescription: (selectedCoupon && selectedCoupon.description) || "",
      couponPrice: (selectedCoupon && selectedCoupon.price) || "",
      gameId: (selectedCoupon && Number(selectedCoupon.gameId)) || "",
      startDate: (selectedCoupon && selectedCoupon.couponUsageStartDate) || "",
      endDate: (selectedCoupon && selectedCoupon.couponUsageEndDate) || "",
      salesStartDate: (selectedCoupon && selectedCoupon.saleStartDate) || "",
      salesEndDate: (selectedCoupon && selectedCoupon.saleEndDate) || "",
      couponAvailable: (selectedCoupon && selectedCoupon.couponsAvailable) || "",
      couponReBuyAble: (selectedCoupon && selectedCoupon.couponReBuyAble) || 1,
      imageFile: (event && event.imageFile) || "",
    },
    validationSchema: Yup.object({
      couponName: Yup.string().required("Please Enter Coupon Name"),
      couponDescription: Yup.string().required(
        "Please Enter Coupon Description"
      ),
      couponPrice: Yup.string().required("Please Enter couponPrice"),
      gameId: Yup.string().required("Please Enter Game Id"),
      startDate: Yup.string().required("Please Enter Start Date"),
      endDate: Yup.string().required("Please Enter End Date"),
      salesStartDate: Yup.string().required("Please Enter sales StartDate"),
      salesEndDate: Yup.string().required("Please Enter Sales EndDate"),
      couponAvailable: Yup.string().required("Please Enter Coupon Available"),
      couponReBuyAble: "",
      imageFile: isEditMode ? Yup.string() : Yup.string().required("Please Enter Iamge"),
    }),
    onSubmit: values => {
      if (selectedFiles.length === 0 && !isEditMode) {
        validation.setFieldError("imageFile", "Please Select a File")
        return
      }
      let authUser = localStorage.getItem("authUser")

      try {
        authUser = JSON.parse(authUser)
      } catch (error) {
        console.error(error)
        dispatch(logoutUser(navigate))
        return
      }
      const token = authUser.token

      const registerUserData = {
        couponName: values["couponName"],
        couponDescription: values["couponDescription"],
        couponPrice: values["couponPrice"],
        gameId: values["gameId"],
        startDate: values["startDate"],
        endDate: values["endDate"],
        salesStartDate: values["salesStartDate"],
        salesEndDate: values["salesEndDate"],
        couponAvailable: values["couponAvailable"],
        couponReBuyAble: values["couponReBuyAble"],
        design: selectedFiles[0],
      }

      const url = `${API_URL}${GET_COUPONS}`

      if (isEditMode) {
        registerUserData.id = selectedCoupon.id.toString()
        registerUserData.design = selectedFiles[0];
        if(selectedFiles[0].preview === selectedCoupon.couponImage) {
        delete registerUserData.design
        }
      }
      axios({
        method: "POST",
        url,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
        data: registerUserData,
      })
        .then(response => {
          setRefetch(refetch + 1)
          toast.success(isEditMode
            ? "Coupons updated successfully"
            : "Coupons registered successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
          toggleModalVisibility()
        })
        .catch(error => {
          console.error(error)
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
          toggleModalVisibility()
        })
    },
  })

  const handleDeleteClick = gameId => {
    setCouponToDelete(gameId)
    setDeleteModalVisibility(true)
  }

  const confirmDelete = () => {
    handleDelete(couponToDelete)
    setDeleteModalVisibility(false)
    setCouponToDelete(null)
  }

  const handleAddCoupon = () => {
    setSelectedCoupon(null)
    setIsEditMode(false)
    setselectedFiles([])
    toggleModalVisibility()
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="">
            <Row className="align-items-center">
              <Col md={8}>
                <div className="page-title-box">
                  <h6 className="page-title">Coupons</h6>
                </div>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={handleAddCoupon}
                  >
                    Create Coupon
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable responsive striped bordered data={coupons} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={modalVisibility}
              toggle={toggleModalVisibility}
              className={props.className}
              centered
            >
              <ModalHeader toggle={toggleModalVisibility} tag="h5">
              {isEditMode ? "Edit Coupons" : "Add Coupons"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Coupon Name</Label>
                        <Input
                          name="couponName"
                          type="text"
                          placeholder="Insert Your Coupon Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.couponName || ""}
                          invalid={
                            validation.touched.couponName &&
                            validation.errors.couponName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.couponName &&
                        validation.errors.couponName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.couponName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Coupon Description</Label>
                        <Input
                          name="couponDescription"
                          type="text"
                          placeholder="Insert Your Coupon Description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.couponDescription || ""}
                          invalid={
                            validation.touched.couponDescription &&
                            validation.errors.couponDescription
                              ? true
                              : false
                          }
                        />
                        {validation.touched.couponDescription &&
                        validation.errors.couponDescription ? (
                          <FormFeedback type="invalid">
                            {validation.errors.couponDescription}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Coupon Price</Label>
                        <Input
                          name="couponPrice"
                          type="number"
                          placeholder="Insert Coupon Price"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.couponPrice || ""}
                          invalid={
                            validation.touched.couponPrice &&
                            validation.errors.couponPrice
                              ? true
                              : false
                          }
                        />
                        {validation.touched.couponPrice &&
                        validation.errors.couponPrice ? (
                          <FormFeedback type="invalid">
                            {validation.errors.couponPrice}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Game Id</Label>
                        <Select
                          value={
                            gameOptions.find(option => option.value === validation.values.gameId) || null
                          }
                          onChange={option =>
                            validation.setFieldValue("gameId", option.value)
                          }
                          options={gameOptions}
                          classNamePrefix="select2-selection"
                        />
                        {validation.touched.gameId &&
                        validation.errors.gameId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.gameId}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Start Date</Label>
                        <Input
                          name="startDate"
                          type="date"
                          placeholder="Insert startDate"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.startDate || ""}
                          invalid={
                            validation.touched.startDate &&
                            validation.errors.startDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.startDate &&
                        validation.errors.startDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.startDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">End Date</Label>
                        <Input
                          name="endDate"
                          type="date"
                          placeholder="Insert endDate"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.endDate || ""}
                          invalid={
                            validation.touched.endDate &&
                            validation.errors.endDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.endDate &&
                        validation.errors.endDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.endDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Sales StartDate</Label>
                        <Input
                          name="salesStartDate"
                          type="date"
                          placeholder="Insert salesStartDate"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.salesStartDate || ""}
                          invalid={
                            validation.touched.salesStartDate &&
                            validation.errors.salesStartDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.salesStartDate &&
                        validation.errors.salesStartDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.salesStartDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Sales EndDate</Label>
                        <Input
                          name="salesEndDate"
                          type="date"
                          placeholder="Insert salesEndDate"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.salesEndDate || ""}
                          invalid={
                            validation.touched.salesEndDate &&
                            validation.errors.salesEndDate
                              ? true
                              : false
                          }
                        />
                        {validation.touched.salesEndDate &&
                        validation.errors.salesEndDate ? (
                          <FormFeedback type="invalid">
                            {validation.errors.salesEndDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Coupon Available</Label>
                        <Input
                          name="couponAvailable"
                          type="number"
                          placeholder="Insert Your Coupon Available"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.couponAvailable || ""}
                          invalid={
                            validation.touched.couponAvailable &&
                            validation.errors.couponAvailable
                              ? true
                              : false
                          }
                        />
                        {validation.touched.couponAvailable &&
                        validation.errors.couponAvailable ? (
                          <FormFeedback type="invalid">
                            {validation.errors.couponAvailable}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Coupon Control</Label>
                        <FormGroup>
                          <Label>
                            <Input
                              type="radio"
                              name="couponReBuyAble"
                              value={0}
                              checked={validation.values.couponReBuyAble === 0}
                              onChange={e => validation.setFieldValue('couponReBuyAble', parseInt(e.target.value))}
                              style={{paddingRight: "10px"}}
                            />
                            {" "}
                            1 Time
                          </Label>
                          <Label style={{paddingLeft: "10px"}}>
                            <Input
                              type="radio"
                              name="couponReBuyAble"
                              value={1}
                              checked={validation.values.couponReBuyAble === 1}
                              onChange={e => validation.setFieldValue('couponReBuyAble', parseInt(e.target.value))}
                            />
                            {" "}
                            Unlimited
                          </Label>
                        </FormGroup>
                        {/* {validation.touched.couponAvailable &&
                        validation.errors.couponAvailable ? (
                          <FormFeedback type="invalid">
                            {validation.errors.couponAvailable}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <Dropzone
                        onDrop={acceptedFiles => {
                          validation.setFieldValue(
                            "imageFile",
                            acceptedFiles.length ? "file_selected" : "",
                            false
                          )
                          validation.setFieldTouched("imageFile", true, false)
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                      {validation.errors.imageFile && (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.imageFile}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="col-6"></Col>
                    <Col className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-light me-1"
                        onClick={toggleModalVisibility}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <DeleteConfirmationModal
              isOpen={deleteModalVisibility}
              toggle={() => setDeleteModalVisibility(!deleteModalVisibility)}
              onConfirm={confirmDelete}
              type={"coupon"}
              title={"Delete Coupon"}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Coupons.propTypes = {
  t: PropTypes.any,
}

export default Coupons
