import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Container, Label, Form, FormFeedback, Input } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import withRouter from 'components/Common/withRouter';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-sm.png";
import { USER_TYPE } from "../../helpers/constants"
import { toast } from 'react-toastify';

const Login = props => {
  const dispatch = useDispatch();

  const [userLogin, setUserLogin] = useState([]);

  const [userType, setUserType] = useState(USER_TYPE.ADMIN);
  const { user, loginError } = useSelector(state => ({
    user: state.Account.user,
    loginError: state.Login.error,
  }));

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      dispatch(apiError(""));
    }
  }, [loginError])
  useEffect(() => {
    if (user) {
      setUserLogin({
        email: user.email,
        password: user.password
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || '',
      password: userLogin.password || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      values.type = userType;
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  document.title = "Login | Coupra";
  return (
    <React.Fragment>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome Back !
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">Username</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Username"
                          type="email"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                      <div className="mb-3">

                          <div className="form-check ">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name={USER_TYPE.ADMIN}
                              value={USER_TYPE.ADMIN}
                              checked={userType === USER_TYPE.ADMIN}
                              onChange={(e) => setUserType(e.target.value)}
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label" htmlFor="customRadioInline1"
                            >
                              Admin
                            </Label>
                          </div>

                          <div className="form-check ">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name={USER_TYPE.COMPANY}
                              value={USER_TYPE.COMPANY}
                              checked={userType === USER_TYPE.COMPANY}
                              onChange={(e) => setUserType(e.target.value)}
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label" htmlFor="customRadioInline2"
                            >
                              Company

                            </Label>
                          </div>

                          <div className="form-check ">
                            <Input
                              type="radio"
                              id="customRadioInline3"
                              name={USER_TYPE.AGENT}
                              value={USER_TYPE.AGENT}
                              checked={userType === USER_TYPE.AGENT}
                              onChange={(e) => setUserType(e.target.value)}
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label" htmlFor="customRadioInline3"
                            >

                              Shop

                            </Label>
                          </div>
                        </div>

                      </div>
                      <div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </div>
                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
