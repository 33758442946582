import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormFeedback,
  Input,
  Label,
} from "reactstrap"
import { useFormik } from "formik"

import { logoutUser } from "../../store/auth/login/actions"
import { API_URL } from "../../helpers/api_helper"
import {
  GET_GAMES,
} from "../../helpers/url_helper"
import axios from "axios"
import * as Yup from "yup"
import { toast } from "react-toastify"
import FormUpload from "../Forms/FormUpload"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import DeleteConfirmationModal from "common/data/confirmModal"

const columnForUsers = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Game Url",
    field: "description",
    sort: "asc",
    width: 270,
  },
  {
    label: "Image",
    field: "image",
    sort: "asc",
    width: 235,
    attributes: {
      style: { width: "235px" },
    },
  },
  {
    label: "Is Active",
    field: "isActive",
    sort: "asc",
    width: 100,
  },
  {
    label: "Is Free",
    field: "isFree",
    sort: "asc",
    width: 100,
  },
  {
    label: "Entry Fee",
    field: "entryFee",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 200,
  },
]

const Games = props => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [gameToDelete, setGameToDelete] = useState(null)
  const toggleModalVisibility = () => {
    setModalVisibility(!modalVisibility)
  }
  const [selectedGame, setSelectedGame] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])
  const [isFreeNewGame, setFreeNewGame] = useState(true)
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if (files.length >= 1) {
      setselectedFiles([files[0]])
    }
  }
  const FILE_SIZE = 160 * 1024
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ]
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (selectedGame && selectedGame.name) || "",
      description: (selectedGame && selectedGame.description) || "",
      isFree: (selectedGame && selectedGame.isFree) || true,
      entryFee: (selectedGame && selectedGame.entryFee) || 3.0,
      imageFile: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Game Name"),
      description: Yup.string().required("Please Enter Game Url"),
      imageFile: isEditMode ? Yup.string(): Yup.string().required("Please Select File"),
    }),
    onSubmit: values => {
      // Check if selectedFiles is empty
      if (selectedFiles.length === 0 && !isEditMode) {
        validation.setFieldError("imageFile", "Please Select a File")
        return
      }
      let authUser = localStorage.getItem("authUser")

      try {
        authUser = JSON.parse(authUser)
      } catch (error) {
        console.error(error)
        dispatch(logoutUser(navigate))
        return
      }
      const token = authUser.token

      const registerUserData = {
        name: values["name"],
        description: values["description"],
        isFree: values["isFree"] ? 1 : 0,
        entryFee: values["entryFee"],
        design: selectedFiles[0],
      }

      const url = `${API_URL}${GET_GAMES}`
      if (isEditMode) {
        registerUserData.id = selectedGame.id.toString()
        registerUserData.design = selectedFiles[0];
        if(selectedFiles[0].preview === selectedGame.image) {  
        delete registerUserData.design
        }
      }
      axios({
        method: "POST",
        url,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
        data: registerUserData,
      })
        .then(response => {
          setRefetch(refetch + 1)
          toast.success(
            isEditMode
              ? "Game updated successfully"
              : "Game registered successfully",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          )
          setIsEditMode(false)
          setSelectedGame(null)
          toggleModalVisibility(false)
        })
        .catch(error => {
          console.error(error)
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
          setIsEditMode(false)
          setSelectedGame(null)
          toggleModalVisibility(false)
        })
    },
  })

  const handleDeleteClick = gameId => {
    setGameToDelete(gameId)
    setDeleteModalVisibility(true)
  }

  const confirmDelete = () => {
    handleDelete(gameToDelete)
    setDeleteModalVisibility(false)
    setGameToDelete(null)
  }

  const [refetch, setRefetch] = useState(0)

  const [games, setGames] = useState({
    columns: columnForUsers,
    rows: [],
  })

  const handleEdit = (gameId, games) => {
    const game = games.find(game => game.id === gameId)
    if (game) {
      setIsEditMode(true)
      setSelectedGame(game)
      const existingFile = {
        preview: game.image,
      };
      setselectedFiles([existingFile]);
      toggleModalVisibility()
    }
  }

  const handleAddGame = () => {
    setSelectedGame(null)
    setIsEditMode(false)
    setselectedFiles([])
    toggleModalVisibility()
  }

  function handleDelete(userId) {
    let authUser = localStorage.getItem("authUser")

    try {
      authUser = JSON.parse(authUser)
    } catch (error) {
      console.error(error)
      dispatch(logoutUser(navigate))
      return
    }
    const token = authUser.token
    const url = `${API_URL}${GET_GAMES}`
    axios({
      method: "DELETE",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: {
        id: userId,
      },
    })
      .then(response => {
        setRefetch(refetch + 1)
        toast.success("game deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
  }

  useEffect(() => {
    function fetchGames() {
      try {
        let authUser = localStorage.getItem("authUser")

        try {
          authUser = JSON.parse(authUser)
        } catch (error) {
          console.error(error)
          dispatch(logoutUser(navigate))
          return
        }
        const token = authUser.token
        const url = `${API_URL}${GET_GAMES}`
        axios({
          method: "GET",
          url,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then(response => {
            const games = response.data.data
            const rows = games.map(game => {
              return {
                name: game.name,
                description: <a href={game.description} target="_blank" rel="noopener noreferrer">{game.description}</a>,
                image: (
                  <img
                    src={game.image}
                    alt="Image"
                    className="w-25 h-auto"
                  />
                ),
                isActive: game.isActive ? "True" : "False",
                isFree: game.isFree ? "True" : "False",
                entryFee: game.entryFee,
                actions: (
                  <div>
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => handleEdit(game.id, games)}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteClick(game.id)}
                    >
                      Delete
                    </Button>
                  </div>
                ),
              }
            })
            setGames({
              columns: columnForUsers,
              rows,
            })
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
    fetchGames()
  }, [refetch])
  document.title = "Games | Coupra"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="">
            <Row className="align-items-center">
              <Col md={8}>
                <div className="page-title-box">
                  <h6 className="page-title">Games</h6>
                </div>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={handleAddGame}
                  >
                    Create Game
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      noBottomColumns={true}
                      responsive
                      striped
                      bordered
                      data={games}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={modalVisibility}
              toggle={toggleModalVisibility}
              className={props.className}
              centered
            >
              <ModalHeader toggle={toggleModalVisibility} tag="h5">
                {isEditMode ? "Edit Game" : "Add Game"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Game Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Game Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Game Url</Label>
                        <Input
                          name="description"
                          type="text"
                          placeholder="Insert Game Url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Entry Fee</Label>
                        <Input
                          name="entryFee"
                          type="number"
                          placeholder="Insert Entry Fee"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.entryFee || ""}
                          invalid={
                            validation.touched.entryFee &&
                            validation.errors.entryFee
                              ? true
                              : false
                          }
                        />
                        {validation.touched.entryFee &&
                        validation.errors.entryFee ? (
                          <FormFeedback type="invalid">
                            {validation.errors.entryFee}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <label
                          className="form-check-label"
                          htmlFor="light-mode-switch"
                        >
                          Is Free?
                        </label>
                        <Input
                          style={{
                            marginLeft: "10px",
                            border: "1px solid #02a499",
                            boxShadow: "0 0 3px #02a499",
                          }}
                          type="checkbox"
                          value={isFreeNewGame}
                          checked={isFreeNewGame}
                          onChange={e => {
                            setFreeNewGame(!isFreeNewGame)
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="col-12">
                      <Dropzone
                        onDrop={acceptedFiles => {
                          validation.setFieldValue(
                            "imageFile",
                            acceptedFiles.length ? "file_selected" : "",
                            false
                          )
                          validation.setFieldTouched("imageFile", true, false)
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                      {validation.errors.imageFile && (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.imageFile}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="col-6"></Col>
                    <Col className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-light me-1"
                        onClick={toggleModalVisibility}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                      >
                        {isEditMode ? "Update" : "Save"}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <DeleteConfirmationModal
              isOpen={deleteModalVisibility}
              toggle={() => setDeleteModalVisibility(!deleteModalVisibility)}
              onConfirm={confirmDelete}
              type={"game"}
              title={"Delete Game"}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Games.propTypes = {
  t: PropTypes.any,
}

export default Games
