import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

const DeleteConfirmationModal = ({ isOpen, toggle, onConfirm, type, title }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody>Are you sure you want to delete this {type}?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirm}>
          Delete
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteConfirmationModal
