//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/auth/login"

export const POST_FORGET_PASSWORD = "/auth/password/forget"
export const POST_RESET_PASSWORD = "/auth/password/reset"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

// Users
export const GET_USERS = "/users"

// Users
export const GET_ADMINS = "/admins"
export const POST_ADMINS = "/admins/create"
export const PUT_ADMINS = "/admins/update"


// Couponss
export const GET_COUPONS = "/coupons"

//Transactions
export const GET_TRANSACTIONS = "/transactions"


// Games
export const GET_GAMES = "/games"
//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"
