import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import * as url from "../../../helpers/url_helper";
import {
  postLogin,
} from "../../../helpers/routes_backend_helper"
import { USER_TYPE } from "../../../helpers/constants"
import { registerUserSuccessful } from "../register/actions"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
      type: user.type,
    });
    localStorage.setItem("authUser", JSON.stringify(response.data));
    yield put(loginSuccess(response.data));
    yield put(registerUserSuccessful(response.data.user))

    const userType = response.data.user.userType;
    if (userType === USER_TYPE.ADMIN)
      history("/users");
    else if (userType === USER_TYPE.COMPANY)
      history("/shops");
    else if (userType === USER_TYPE.AGENT)
      history("/coupons");
    else if (userType === USER_TYPE.SHOP)
      history("/coupons");
    else
      yield put(apiError("Invalid User Type"));

  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
