import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import { API_URL } from "../../helpers/api_helper"
import { GET_TRANSACTIONS, } from "../../helpers/url_helper"
import { logoutUser } from "../../store/auth/login/actions"
import axios from "axios"

const columnForTransactions = [
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 270,
  },
  {
    label: "Payment Method",
    field: "payment_method",
    sort: "asc",
    width: 200,
  },
  {
    label: "Description",
    field: "description",
    sort: "asc",
    width: 100,
  },
  {
    label: "Shop",
    field: "shop",
    sort: "asc",
    width: 100,
  },
  {
    label: "Customer",
    field: "customer",
    sort: "asc",
    width: 150,
  },
  {
    label: "Coupen Name",
    field: "coupen_name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 200,
  },
]
const Transactions = props => {
  const [modalVisibility, setModalVisibility] = useState(false)
  
  /*
  const toggleModalVisibility = () => {
    setModalVisibility(!modalVisibility)
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: selectedUser ? selectedUser.name : "",
      email: selectedUser ? selectedUser.email : "",
      password: "", // Assuming password is not fetched or should be reset
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string().required("Please Enter User Email"),
      password: Yup.string().required("Please Enter User Password"),
    }),
    onSubmit: values => {
      let authUser = localStorage.getItem("authUser")

      try {
        authUser = JSON.parse(authUser)
      } catch (error) {
        console.error(error)
        dispatch(logoutUser(navigate))
        return
      }
      const token = authUser.token

      const registerUserData = {
        name: values["name"],
        email: values["email"],
        password: values["password"],
        type: "user",
      }

      const url = isEditMode
        ? `${API_URL}/${PUT_ADMINS}/${selectedUser.id}`
        : `${API_URL}/${POST_ADMINS}`
      const method = isEditMode ? "PUT" : "POST"

      axios({
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: registerUserData,
      })
        .then(response => {
          toast.success(
            isEditMode
              ? "User updated successfully"
              : "User registered successfully",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          )
          toggleModalVisibility()
        })
        .catch(error => {
          console.error(error)
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
          toggleModalVisibility()
        })
    },
  })
  */

  const [refetch, setRefetch] = useState(0)
  const [transactions, setTransactions] = useState({
    columns: columnForTransactions,
    rows: [],
  })

  /*const handleEdit = (userId, users) => {
    const user = users.find(user => user.id === userId)
    if (user) {
      setIsEditMode(true)
      setSelectedUser(user);
      toggleModalVisibility();
    }
  }

  const handleAddAdmin = () => {
    setSelectedUser(null);
    setIsEditMode(false);
    toggleModalVisibility();
  };

  const handleDeleteClick = userId => {
    setUserToDelete(userId)
    setDeleteModalVisibility(true)
  }

  const confirmDelete = () => {
    handleDelete(userToDelete)
    setDeleteModalVisibility(false)
    setUserToDelete(null)
  }

  function handleDelete(userId) {
    let authUser = localStorage.getItem("authUser")

    try {
      authUser = JSON.parse(authUser)
    } catch (error) {
      console.error(error)
      dispatch(logoutUser(navigate))
      return
    }
    const token = authUser.token
    const url = `${API_URL}${GET_ADMINS}`
    axios({
      method: "DELETE",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: {
        id: userId,
      },
    })
      .then(response => {
        setRefetch(refetch + 1)
        toast.success("user deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
  }
      */

  useEffect(() => {
    function fetchTransactions() {
      try {
        let authUser = localStorage.getItem("authUser")

        try {
          authUser = JSON.parse(authUser)
        } catch (error) {
          console.error(error)
          dispatch(logoutUser(navigate))
          return
        }
        const token = authUser.token
        const url = `${API_URL}${GET_TRANSACTIONS}`
        axios({
          method: "GET",
          url,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then(response => {
            const transactions = response.data.data;
            const rows = transactions.map(transaction => {
                return {
                  amount: `${transaction.transactionAmount}${transaction.transactionCurrency}`,
                  status: transaction.transactionStatus,
                  payment_method: transaction.transactionProvider,
                  description: transaction.transactionDescription,
                  shop: transaction.coupon.description,
                  customer: transaction?.user?.email,
                  coupen_name: transaction?.coupon?.name,
                  date: transaction.createdAt,
                  actions: (
                    <div>
                      {/* Edit Button */}
                      {/* <Button 
                        color="warning"
                        size="sm"
                        onClick={() => handleEdit(coupon.id, coupons)}
                      >
                        Edit
                      </Button>{" "} */}
                      {/* Delete Button */}
                      {/* <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDeleteClick(+coupon.id)}
                      >
                        Delete
                      </Button> */}
                    </div>
                  ),
                }
            })
            setTransactions({
              columns: columnForTransactions,
              rows,
            })
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
    fetchTransactions()
  }, [refetch])
  document.title = "Transcations"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="">
            <Row className="align-items-center">
              <Col md={8}>
                <div className="page-title-box">
                  <h6 className="page-title">Transactions</h6>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      noBottomColumns={true}
                      responsive
                      striped
                      bordered
                      data={transactions}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Modal
              isOpen={modalVisibility}
              toggle={toggleModalVisibility}
              className={props.className}
              centered
            >
              <ModalHeader toggle={toggleModalVisibility} tag="h5">
                Add Admin
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Admin Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Admin Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Insert Admin Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Insert User Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="col-6"></Col>
                    <Col className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-light me-1"
                        onClick={toggleModalVisibility}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <DeleteConfirmationModal
              isOpen={deleteModalVisibility}
              toggle={() => setDeleteModalVisibility(!deleteModalVisibility)}
              onConfirm={confirmDelete}
            /> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Transactions.propTypes = {
  t: PropTypes.any,
}

export default Transactions
