import axios from "axios"

//apply base url for axios
export const API_URL = process.env.REACT_APP_BASE_URL || "https://coupra-node-api.gamesfi.live"

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.post["Content-Type"] = "application/json"

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  const response = await axiosApi({
    method: "POST",
    url: API_URL + url,
    data: data,
    headers: {
      "Content-Type": "application/json"
    }
  })
  return response.data
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
